import {useEffect, useState, createRef} from 'react'
import {isUserLoggedIn} from '@utils'
import {useSkin} from '@hooks/useSkin'
import {ChevronLeft} from 'react-feather'
import '@styles/base/pages/page-auth.scss'
import {Formik} from "formik"
import * as yup from "yup"
import classnames from 'classnames'
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Row, Col, CardTitle, CardText, Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap'
import {sendForgetPasswordEmail} from '@store/actions/auth'
import logo from '@src/assets/images/logo.png'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ForgotPassword = () => {
    const {executeRecaptcha} = useGoogleReCaptcha()
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [serverErrors, setServerErrors] = useState([])
    const [visible, setVisible] = useState(false)
    const [forgetPasswordRes, setForgetPasswordRes] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formRef, setFormRef] = useState(null)

    const [captchaValue, setCaptchaValue] = useState(null)
    const [captchaError, setCaptchaError] = useState(false)

    const [skin, setSkin] = useSkin()
    const authSelector = useSelector(store => store.auth)
    const dispatch = useDispatch()

    const sweetAlert = withReactContent(Swal)

    useEffect(() => {
        const forgetPasswordResponse = authSelector?.forgetPasswordResponse ?? null
        if (forgetPasswordResponse) {
            const {status, msg} = forgetPasswordResponse
            setForgetPasswordRes(forgetPasswordResponse)
            setVisible(true)
            setIsSubmitting(false)
            setServerErrors([])
            if (!status) {
                setErrorMessage(msg)
                const timer = setTimeout(() => {
                    setVisible(false)
                    setErrorMessage("")
                    clearTimeout(timer)
                }, 50000)
            } else {
                formRef?.resetForm()
                setSuccessMessage(msg)
                const timer = setTimeout(() => {
                    setVisible(false)
                    setSuccessMessage("")
                    clearTimeout(timer)
                }, 5000)
            }
        }

        if (authSelector.error) {
            setIsSubmitting(false)
            setVisible(true)
            setServerErrors([])
            setSuccessMessage("")
            setForgetPasswordRes(null)
            const errors = authSelector?.error?.response?.data?.errors
            if (errors) {
                for (const property in errors) {
                    setServerErrors((prevState) => {
                        return prevState.concat(...errors[property])
                    })
                }
            }
        }
    }, [authSelector])

    useEffect(() => {
        setVisible(false)
        setErrorMessage("")
        setSuccessMessage("")
    }, [])

    const illustration = skin === 'dark' ? 'forgot-password-v2-dark.svg' : 'forgot-password-v2.svg',
        source = require(`@src/assets/images/pages/${illustration}`).default

    const forgetMyPassword = async (values, form) => {
        setIsSubmitting(true)
        const token = await executeRecaptcha('forget_password')
        if (token) {
            setServerErrors([])
            setVisible(false)
            dispatch({type: "SERVER_ERROR_NULL"})
            dispatch(sendForgetPasswordEmail({email: values?.email, re_captcha_token: token}))
            setFormRef(form)
        } else {
            sweetAlert.fire({
                title: 'Captcha Error',
                icon: 'error',
                html: '<p>Execute recaptcha not yet available.</p>',
                confirmButtonText: 'Reload',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }

    if (!isUserLoggedIn()) {
        return (
            <div className='auth-wrapper auth-v2'>
                <Row className='auth-inner m-0'>
                    <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
                        <img src={logo} width="140" alt='logo'/>
                    </Link>
                    {/* <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
            <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
              <img className='img-fluid' src={source} alt='Login V2' />
            </div>
          </Col> */}
                    <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' sm='12'>
                        <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='5'>
                            <CardTitle tag='h2' className='font-weight-bold mb-1'>
                                Forgot Password? 🔒
                            </CardTitle>
                            <CardText className='mb-2'>
                                Enter your email and we'll send you instructions to reset your password
                            </CardText>

                            <Alert color={!forgetPasswordRes?.status ? 'danger' : 'success'} isOpen={visible} toggle={() => setVisible(false)}>
                                <div className='alert-body'>
                                    {forgetPasswordRes?.msg}
                                    {serverErrors.length > 0 ? serverErrors.map((error, index) => (
                                        <p key={index}>{error}</p>
                                    )) : ""}
                                </div>
                            </Alert>
                            <Formik
                                initialValues={{
                                    email: ""
                                }}
                                validationSchema={yup.object().shape({
                                    email: yup.string().email().required("This field is required")
                                })}
                                onSubmit={(values, form) => {
                                    forgetMyPassword(values, form)
                                }}
                            >
                                {({
                                      errors,
                                      handleBlur,
                                      handleChange,
                                      handleSubmit,
                                      touched,
                                      values
                                  }) => (
                                    <Form className='auth-reset-password-form mt-2' onSubmit={handleSubmit}>
                                        <div className='form-group form-password-toggle'>
                                            <Label className={classnames({
                                                'is-invalid': touched.email && errors.email,
                                                'form-label': true
                                            })} htmlFor={`email`}>
                                                Email<span className="is-invalid">*</span>
                                            </Label>
                                            <Input
                                                type='email'
                                                name='email'
                                                placeholder='john@example.com'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                id={`email`}
                                                className={classnames({'is-invalid': touched.email && errors.email})}
                                            />
                                            <div className="validation-err">{touched.email && errors.email}</div>
                                        </div>
                                        <Button.Ripple type='submit' disabled={isSubmitting} color='primary' block>
                                            {isSubmitting ? 'Loading...' : 'Send reset link'}
                                        </Button.Ripple>
                                    </Form>)}
                            </Formik>

                            <p className='text-center mt-2'>
                                <Link to='/login?target=web'>
                                    <ChevronLeft className='mr-25' size={14}/>
                                    <span className='align-middle'>Back to login</span>
                                </Link>
                            </p>
                        </Col>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return <Redirect to='/'/>
    }
}

export default ForgotPassword
