import {Suspense, useContext, lazy, useState} from 'react'
import HomeComponent from '@src/views/outerPages/Home'
import AboutComponent from '@src/views/outerPages/AboutUs'
import BlogComponent from '@src/views/outerPages/Blog'
import BlogDetailComponent from '@src/views/outerPages/DetailBlog'
import FeaturesComponent from '@src/views/outerPages/Features'
import PricingComponent from '@src/views/outerPages/Pricing'
import TermsAndConditionsComponent from '@src/views/outerPages/TermsAndConditions'
import ContactComponent from '@src/views/outerPages/Contact'
import PrivacyPolicyComponent from '@src/views/outerPages/PrivacyPolicy'
import Unsubscribe from "@src/views/pages/misc/Unsubscribe"
import {isUserLoggedIn} from '@utils'
import {useLayout} from '@hooks/useLayout'
import {AbilityContext} from '@src/utility/context/Can'
import {useRouterTransition} from '@hooks/useRouterTransition'
import LayoutWrapper from '@layouts/components/layout-wrapper'
import {BrowserRouter as AppRouter, Route, Switch, Redirect} from 'react-router-dom'
import {Routes} from './routes'
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import VerticalLayoutBrands from '@src/layouts/VerticalLayoutBrands'
import VerticalLayoutRd from '@src/layouts/VerticalLayoutRd'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import {PropTypes} from "prop-types"

const Router = () => {
    const ability = useContext(AbilityContext)

    const [layout, setLayout] = useLayout()
    const [transition, setTransition] = useRouterTransition()

    const DefaultLayout = layout === 'horizontal' ? 'HorizontalLayout' : 'VerticalLayout'
    const Layouts = {BlankLayout, VerticalLayout, VerticalLayoutBrands, VerticalLayoutRd, HorizontalLayout}
    const currentActiveItem = null

    const LayoutRoutesAndPaths = (layout) => {
        const LayoutRoutes = []
        const LayoutPaths = []
        if (Routes) {
            Routes.filter(route => {
                if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
                    LayoutRoutes.push(route)
                    LayoutPaths.push(route.path)
                    return true
                }
                return false
            })
        }
        return {LayoutRoutes, LayoutPaths}
    }


    const NotAuthorized = lazy(() => import('@src/views/pages/misc/NotAuthorized'))

    const ErrorPage = lazy(() => import('@src/views/pages/misc/Error'))

    const excludeRedirectRoutes = [
        '/purchase-plan',
        '/rd/upgrade-membership',
        '/pending-account-msg',
        '/subscription-plan-success'
    ]

    const FinalRoute = ({route, ...props}) => {
        const {meta = {}} = route
        const {action = null, resource = null, authRoute, publicRoute} = meta
        const user = JSON.parse(isUserLoggedIn())
        const isLoggedIn = !!user
        if (!isLoggedIn && !authRoute && !publicRoute) {
            if (!excludeRedirectRoutes.includes(route.path)) {
                localStorage.setItem('redirect_url', JSON.stringify({path: window.location.href, role: resource}))
            }
            return <Redirect exact to='/login?target=other'/>
        }
        if (authRoute && isLoggedIn) return <Redirect exact to='/'/>
        if (isLoggedIn && !ability.can(action || 'read', resource)) {
            const redirects = {
                brand: user.subscription_exp ? '/subscription-plans' : '/dashboard/brands',
                rd: user.profile_approved ? '/dashboard/rd' : '/pending-account-msg',
                admin: '/dashboard/admin',
                sub_admin: '/dashboard/admin'
            }
            return <Redirect to={redirects[user.role]}/>
        }
        return <route.component {...props} />
    }

    FinalRoute.propTypes = {
        route: PropTypes.shape({
            path: PropTypes.string.isRequired,
            exact: PropTypes.bool,
            meta: PropTypes.shape({
                action: PropTypes.string,
                resource: PropTypes.string,
                authRoute: PropTypes.bool,
                publicRoute: PropTypes.bool
            }),
            component: PropTypes.oneOfType([
                PropTypes.elementType,
                PropTypes.node
            ]).isRequired
        }).isRequired
    }

    const RouteUnderSwitch = (route, routerProps) => (
        <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => {
                Object.assign(routerProps, {...props, meta: route.meta})
                return (
                    <Suspense fallback={null}>
                        <LayoutWrapper
                            layout={DefaultLayout}
                            transition={transition}
                            setTransition={setTransition}
                            {...route.appLayout && {appLayout: route.appLayout}}
                            {...route.meta && {routeMeta: route.meta}}
                            {...route.className && {wrapperClass: route.className}}
                        >
                            <FinalRoute route={route} {...props} />
                        </LayoutWrapper>
                    </Suspense>
                )
            }}
        />
    )

    const ResolveRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const LayoutTag = Layouts[layout]
            const {LayoutRoutes, LayoutPaths} = LayoutRoutesAndPaths(layout)
            const routerProps = {}
            return (
                <Route path={LayoutPaths} key={index}>
                    <LayoutTag
                        routerProps={routerProps}
                        layout={layout}
                        setLayout={setLayout}
                        transition={transition}
                        setTransition={setTransition}
                        currentActiveItem={currentActiveItem}
                    >
                        <Switch>
                            {LayoutRoutes.map(route => RouteUnderSwitch(route, routerProps))}
                        </Switch>
                    </LayoutTag>
                </Route>
            )
        })
    }

    const SingleRoute = (component, path) => {
        return <Route exact
                      path={path}
                      render={() => <Layouts.BlankLayout>{component}</Layouts.BlankLayout>}
        />
    }

    return (
        <AppRouter basename={process.env.REACT_APP_BASENAME}>
            <Switch>
                {SingleRoute(<HomeComponent/>, '/')}
                {SingleRoute(<BlogComponent/>, '/blog')}
                {SingleRoute(<BlogDetailComponent/>, '/blog/:id')}
                {SingleRoute(<AboutComponent/>, '/about')}
                {SingleRoute(<FeaturesComponent/>, '/features')}
                {SingleRoute(<PricingComponent/>, '/pricing')}
                {SingleRoute(<TermsAndConditionsComponent/>, '/terms-and-conditions')}
                {SingleRoute(<ContactComponent/>, '/contact')}
                {SingleRoute(<PrivacyPolicyComponent/>, '/privacy-policy')}
                {SingleRoute(<Unsubscribe/>, '/unsubscribe/:token')}
                {SingleRoute(<NotAuthorized/>, '/misc/not-authorized')}
                {ResolveRoutes()}
                <Route path='*' component={ErrorPage}/>
            </Switch>
        </AppRouter>
    )
}

export default Router
