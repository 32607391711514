import {Fragment, useState, useEffect} from 'react'
import '@styles/base/pages/page-faq.scss'
import {getBrandDashboardNotifications} from '@store/actions/brandsDashboard'
import {Link, useHistory} from 'react-router-dom'
import {Row, Col, TabContent, Card, Spinner} from 'reactstrap'
import {useSelector, useDispatch} from 'react-redux'
import CardBody from 'reactstrap/lib/CardBody'
import CustomPagination from '@src/views/components/pagination/CustomPagination'

const BrandNotifications = () => {
    const [isPageLoading, setIsPageLoading] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory()
    const brandsDashboardSlices = useSelector(store => store.brandsDashboardSlice)

    useEffect(() => {
        if (!!brandsDashboardSlices?.brandNotifications) {
            // console.log('new  list', brandsDashboardSlices?.brandNotifications)
            setIsPageLoading(false)
        }
    }, [brandsDashboardSlices])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_USERDATA}`))
        if (!!user && user?.subscriptionexp) history.push('/subscription-plans')

        setIsPageLoading(true)
        dispatch(getBrandDashboardNotifications(false))
    }, [])

    const handleCustomPagination = pageUrl => {
        setIsPageLoading(true)
        dispatch(getBrandDashboardNotifications(true, pageUrl))
    }

    const handleRedirectMessage = (user) => {
        localStorage.setItem('selectedChatRd', JSON.stringify({
            id: user.user_id,
            name: user.name,
            profile_img: user.profile_img
        }))
        history.push(`/brands/apps/chat/chatBrands/${user.user_id}`)
    }

    const renderTabContent = () => {
        return brandsDashboardSlices?.brandNotifications?.data?.map((item, index) => {
            return (
                <Fragment>
                    {item.type === 'message' &&
                        <Card>
                            <CardBody>
                                <button className='btn btn-link p-0' onClick={() => handleRedirectMessage(item)}>
                                    <small className='notification-text'>
                                        {item.message}
                                    </small>
                                </button>
                            </CardBody>
                        </Card>
                    }
                    {item.type === 'request' &&
                        <Card>
                            <Link to={`/dashboard/brands/nutrition-detail/${item.event_id}`}>
                                <CardBody>
                                    <small className='notification-text'>
                                        {item.message}
                                    </small>
                                </CardBody>
                            </Link>
                        </Card>
                    }

                    {item.type === 'invoice_sent' &&
                        <Card>
                            <Link to={`/dashboard/brands/nutrition-detail/${item.event_id}`}>
                                <CardBody>
                                    <small className='notification-text'>
                                        {item.message}
                                    </small>
                                </CardBody>
                            </Link>
                        </Card>
                    }
                    {item.type === 'subscription' &&
                        <Card>
                            <Link to="/dashboard/brands/subscription-plan">
                                <CardBody>
                                    <small className='notification-text'>
                                        {item.message}
                                    </small>
                                </CardBody>
                            </Link>
                        </Card>
                    }
                </Fragment>
            )

        })
    }
    return (
        <Fragment>
            {isPageLoading && (<div className="w-100 my-4 text-center">
                <Spinner color='primary'/>
            </div>)}
            {!isPageLoading && (<Fragment>
                <Row>
                    <Col sm='12'>
                        <h3 className="mb-2">All Notifications</h3>
                        <TabContent activeTab={1}>{renderTabContent()}</TabContent>
                    </Col>
                </Row>
                <CustomPagination pageLinks={brandsDashboardSlices?.brandNotifications?.pages} onPaginationClick={handleCustomPagination}/>
            </Fragment>)}

        </Fragment>
    )
}

export default BrandNotifications
